/**
 *
 * ChangeStorePage
 *
 */

import * as React from 'react';

import { Col, Row, message } from 'antd';
import { compose, Dispatch } from 'redux';

import { Avatar } from 'components/Company/Avatar';
import Edit from './Edit.svg';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import Modal from 'components/Modal/Modal';
import PageHeader from 'components/PageHeader/PageHeader';
import { ContainerState, RootState } from './types';
import StoreForm from 'components/StoreForm';
import StoreList from 'components/StoreList';
import bottomBg from './bottom-bg.png';
import { connect } from 'react-redux';
import { createStoreSubmit } from './actions';
import { createStructuredSelector } from 'reselect';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import media from 'utils/mediaStyle';
import messages from './messages';
import { push } from 'connected-react-router';
import reducer from './reducer';
import saga from './saga';
import selectChangeStorePage from './selectors';
import { setSelectedStore } from 'containers/MainLayout/actions';
import styled from 'styled-components';
import translations from 'translations';
import LoadingIndicator from 'components/LoadingIndicator';
import { Store } from 'types/schema';

interface OwnProps {
  changestorepage: ContainerState;
}

interface StateProps {}

interface DispatchProps {
  onChangeStore: (store: Store) => void;
  onCreateStore: (store: Store) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

const Container = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 720px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 8pt;
  flex: 1;
  background-image: url(${bottomBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -10px 20px;
  margin-bottom: 8pt;
  ${media.md`
    padding: 10pt 20pt;
  `};
`;

const Heading = styled.h4`
  margin-bottom: 6pt;
  text-transform: uppercase;
`;

export class ChangeStorePage extends React.PureComponent<Props> {
  // TODO: Should be moved to a separate component. All these fields are only for modal.
  public state = {
    isOpenEditModal: false,
    imageUrl: '',
    formRef: React.createRef(),
  };

  public onAddStore = () => {
    const { buyer } = this.props.changestorepage;
    buyer.addStoreDisabled
      ? message.error(translations(messages.disableAddingStore))
      : this.setState({
          isOpenEditModal: true,
        });
  };

  public onCloseEditModal = () => {
    this.setState({
      isOpenEditModal: false,
    });
  };

  public onSubmitStore = (store: any) => {
    this.props.onCreateStore({
      ...store,
      imageUrl: this.state.imageUrl,
    });
    this.setState({
      isOpenEditModal: false,
    });
  };

  public onSetImageUrl = (url: string) => this.setState({ ...this.state, imageUrl: url });

  public render() {
    const { buyer } = this.props.changestorepage;
    return (
      <Container>
        <Helmet>
          <title>{`${translations(messages.header)} | Nhà Cung Cấp Kamereo`}</title>
          <meta name="description" content={`${translations(messages.header)}`} />
        </Helmet>
        <PageHeader size="large" center>
          <FormattedMessage {...messages.header} />
        </PageHeader>
        {buyer.loading ? (
          <LoadingIndicator />
        ) : (
          <StoreList stores={buyer.stores} onChangeStore={this.props.onChangeStore} onAddStore={this.onAddStore} />
        )}
        <Modal
          size="large"
          headerLabel={translations(messages.headerLabel)}
          headerIcon={Edit}
          isOpen={this.state.isOpenEditModal}
        >
          <Row gutter={8}>
            <Col lg={8} xs={24}>
              <Heading>{translations(messages.storeImage)}</Heading>
              <Avatar src={this.state.imageUrl} onUploadImageSuccess={this.onSetImageUrl} />
            </Col>
            <Col lg={16} xs={24}>
              {this.state.isOpenEditModal ? (
                <StoreForm
                  store={{
                    name: '',
                    location: { address: '', city: 'Ho Chi Minh', district: 'District 1' },
                    telephone: '',
                  }}
                  onCancel={this.onCloseEditModal}
                  onSubmit={this.onSubmitStore}
                />
              ) : null}
            </Col>
          </Row>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  changestorepage: selectChangeStorePage(),
});

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): DispatchProps {
  return {
    onChangeStore: (s: Store) => {
      dispatch(setSelectedStore(s));
      dispatch(push('/'));
    },
    onCreateStore: (s: Store) => dispatch(createStoreSubmit(s)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// <OwnProps> restricts access to the HOC's other props. This component must not do anything with reducer hoc
const withReducer = injectReducer<OwnProps>({
  key: 'changeStorePage',
  reducer: reducer,
});
// <OwnProps> restricts access to the HOC's other props. This component must not do anything with saga hoc
const withSaga = injectSaga<OwnProps>({ key: 'changeStorePage', saga: saga });

export default compose(withReducer, withSaga, withConnect)(ChangeStorePage);
