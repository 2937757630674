/*
 *
 * ChangeStorePage reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  buyer: { loading: true, stores: [], addStoreDisabled: false },
};

function changeStorePageReducer(state: ContainerState = initialState, action: ContainerActions) {
  switch (action.type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case ActionTypes.FETCH_BUYER_REQUEST:
    case ActionTypes.CREATE_STORE_REQUEST:
    case ActionTypes.FETCH_ADD_STORE_DISABLED_REQUEST:
      return { ...state, buyer: { ...state.buyer, loading: true } };
    case ActionTypes.FETCH_BUYER_SUCCESS:
      return { ...state, buyer: { ...state.buyer, ...action.payload, loading: false } };
    case ActionTypes.CREATE_STORE_SUCCESS:
      return { ...state, buyer: { ...state.buyer, loading: false, stores: [...state.buyer.stores, action.payload] } };
    case ActionTypes.FETCH_ADD_STORE_DISABLED_SUCCESS:
      return { ...state, buyer: { ...state.buyer, loading: false, addStoreDisabled: action.payload } };
    default:
      return state;
  }
}

export default changeStorePageReducer;
